export default {
  en: {
    contactUs: 'Contact us',
    menu: {
      about: 'About',
      contact: 'Contact'
    },
    head: {
      title: 'The future of legal service',
      subtitle: 'Professionals powered by startups and creative projects. Peace of mind at every step of your journey.',
    },
    about: {
      title: 'What makes us better than the typical firm',
      philosophy: 'Our philosophy is based on fully personalized service; you are not just another account.',
      team: 'We are a team.',
      despite: 'Despite being a team of young professionals we are aware of the needs and evolution of the actual market.',
      innovation: 'Innovation is our drive.'
    },
    brands: {
      trusted: 'Trusted by amazing brands'
    },
    experience: {
      title: 'Our fields of experience',
      fields: [
        {
          title: 'Fintech & Financial',
          subtitle: 'Legal advice to companies /financial institutions ITFs, Sofom, Sofipo, etc.',
          list: [
            'Obtaining authorization to operate (CNBV, Banxico, CONDUSEF)',
            'Customer relationship (contracts, terms and conditions, privacy notices)',
            'Attention to authorities (CNBV, Banxico, CONDUSEF)',
            'AML',
            'Attention and prevention of financial crimes',
            'Compliance'
          ],
          icon: 'bank-icon.png'
        },
        {
          title: 'IT',
          subtitle: '',
          list: [
            'Legal framework for the development of technology businesses',
            'Registration of applications and websites',
            'Terms and Conditions',
            'Notice of Privacy',
            'Software licensing',
            'Hiring developers or programmers'
          ],
          icon: 'computer-icon.png'
        },
        {
          title: 'Commercial',
          subtitle: '',
          list: [
            'Foreign investment',
            'Contracting with clients',
            'Procurement with third parties',
            'National and international negotiation',
            'Terms and conditions for the provision of services',
            'Trademark',
            'Compliance'
          ],
          icon:'store-icon.png'
        },
        {
          title: 'Corporate',
          subtitle: '',
          list: [
            'Companies establishment',
            'Corporate governance',
            'Shareholders meetings',
            'Dissolutions',
            'Transformations'
          ],
          icon: 'brief-icon.png'
        }
      ]
    },
    tellUs: {
      title: 'Tell us about your project & we´ll walk you through.'
    },
    contact: {
      title: 'Contact us',
      subtitle: 'Tell us about your project',
      name: 'Name',
      firstName: 'First name',
      lastName: 'Last name',
      email: 'Email address',
      company: 'Company',
      message: 'Message',
      send: 'Send'
    }
  },
  es: {
    contactUs: 'Contáctanos',
    menu: {
      about: 'Acerca de',
      contact: 'Contacto'
    },
    head: {
      title: 'El futuro del servicio legal',
      subtitle: 'Profesionales impulsados por startups y proyectos creativos. Tranquilidad en cada paso de tu viaje.',
    },
    about: {
      title: 'Lo que nos hace mejores que la firma típica',
      philosophy: 'Nuestra filosofía se basa en un servicio totalmente personalizado; No eres solo otra cuenta.',
      team: 'Somos un equipo.',
      despite: 'A pesar de ser un equipo de jóvenes profesionales, somos conscientes de las necesidades y la evolución del mercado real.',
      innovation: 'La innovación es nuestro impulso.'
    },
    brands: {
      trusted: 'Con la confianza de marcas increíbles'
    },
    experience: {
      title: 'Nuestros campos de experiencia',
      fields: [
        {
          title: 'Fintech y Financiero',
          subtitle: 'Asesoramiento legal a empresas / instituciones financieras ITF, Sofom, Sofipo, etc.',
          list: [
            'Obtención de autorización para operar (CNBV, Banxico, CONDUSEF)',
             'Relación con el cliente (contratos, términos y condiciones, avisos de privacidad)',
             'Atención a las autoridades (CNBV, Banxico, CONDUSEF)',
             'AML',
             "Atención y prevención de delitos financieros",
             'Conformidad'
          ],
          icon: 'bank-icon.png'
        },
        {
          title: 'IT',
          subtitle: '',
          list: [
            'Marco legal para el desarrollo de negocios tecnológicos',
             'Registro de aplicaciones y sitios web',
             'Términos y Condiciones',
             'Aviso de privacidad',
             'Licencia de software',
             'Contratación de desarrolladores o programadores'
          ],
          icon: 'computer-icon.png'
        },
        {
          title: 'Comercial',
          subtitle: '',
          list: [
            'Inversión extranjera',
             "Contratación con clientes",
             "Contratación con terceros",
             'Negociación nacional e internacional',
             'Términos y condiciones para la prestación de servicios',
             'Marca comercial',
             'Conformidad'
          ],
          icon:'store-icon.png'
        },
        {
          title: 'Corporativo',
          subtitle: '',
          list: [
            "Establecimiento de empresas",
             'Gobierno corporativo',
             "Juntas de accionistas",
             'Disoluciones',
             'Transformaciones'
          ],
          icon: 'brief-icon.png'
        }
      ]
    },
    tellUs: {
      title: 'Cuéntanos sobre tu proyecto y te guiaremos.'
    },
    contact: {
      title: 'Contáctanos',
      subtitle: 'Cuéntanos sobre tu proyecto',
      name: 'Nombre',
      firstName: 'Nombre',
      lastName: 'Apellido',
      email: 'Correo electrónico',
      company: 'Empresa',
      message: 'Mensaje',
      send: 'Enviar'
    }
  }
}
