<template lang="pug">
  div.cont
    toolbar-component

    //- .toolbar
    //-   b-container
    //-     b-row.align-items-center
    //-       b-col(cols="4")
    //-         b-link.text-reset Eng/Esp
    //-       b-col(cols="4")
    //-         .d-flex.align-items-center.justify-content-center
    //-           b-img(src="@/assets/images/full-logo.png", height="40")
    //-       b-col(cols="4")
    //-         .d-flex.align-items-center.justify-content-end
    //-           b-img(src="@/assets/images/menu-icon.png", height="10", style="cursor:pointer;")

    .section.header.d-flex.align-items-center.gradient
      b-container
        b-row.justify-content-center.mb-5
          b-col(md="8")
            h3.mb-0.roman-title.text-center {{ dictionary.head.title }}
        b-row.justify-content-center.mb-5
          b-col(md="8")
            p.mb-0.text-center {{ dictionary.head.subtitle }}
        b-row.justify-content-center.mb-5
          b-col
            b-img(src="@/assets/images/top-ranked.png", height="70", center)
    .divider
    .section.d-flex.align-items-center
      b-container
        b-row.justify-content-center.mb-5
          b-col(md="8")
            h3.mb-0.roman-title.text-center {{ dictionary.about.title }}
        b-row.justify-content-center
          b-col(md="8")
            p.text-center {{ dictionary.about.philosophy }}
            p.bold.text-center {{ dictionary.about.team }}
            p.text-center {{ dictionary.about.despite }}
            p.bold.text-center {{ dictionary.about.innovation }}
            .text-center
              b-button.px-4(variant="outline-dark", style="padding-bottom:2px;", :to="{name: 'Contact'}") {{ dictionary.contactUs }}
    .divider
    .w-100(style="background:white;")
      .section.d-flex.align-items-center
        b-container
          b-row.align-items-center
            b-col.mb-5.mb-md-0(md="6")
              h3.mb-0.roman-title.text-center {{ dictionary.brands.trusted }}
            b-col(md="6")
              b-row.mb-5.align-items-center
                b-col(cols="6")
                  .px-4.px-lg-5
                    b-img(fluid, src="@/assets/images/bitinvestment.png", center)
                b-col(cols="6")
                  .px-4.px-lg-5
                    b-img(fluid, src="@/assets/images/flink.png", center)
              b-row.mb-5.align-items-center
                b-col(cols="6")
                  .px-4.px-lg-5
                    b-img(fluid, src="@/assets/images/revult.png", center)
                //- b-col(cols="6")
                //-   .px-4.px-lg-5
                //-     b-img(fluid, src="@/assets/images/sbc.png", center)
                b-col(cols="6")
                  .px-4.px-lg-5
                    b-img(fluid, src="@/assets/images/snowball.png", center)
              b-row.align-items-center.justify-content-center
                b-col(cols="6")
                  .px-4.px-lg-5
                    b-img(fluid, src="@/assets/images/unipagos.png", center)
    .divider
    .section.d-flex.align-items-center
      b-container
        b-row.justify-content-center
          b-col(md="8")
            h3.mb-5.roman-title.text-center {{ dictionary.experience.title }}
            carousel(:per-page="1", pagination-color="#ccc", :navigation-enabled="true", navigationNextLabel="→", navigationPrevLabel="←")
              slide(v-for="(field, index) in dictionary.experience.fields", :key="index")
                .d-flex.align-items-center.justify-content-center.flex-column.text-center
                  b-img.mb-5(height="35", :src="generateImage(field.icon)")
                  p {{ field.title }}
                  p(v-if="field.subtitle") {{ field.subtitle }}
                  ul.dashed
                    li(v-for="dot in field.list") {{ dot }}
    .divider
    .section.d-flex.align-items-center.gradient-reverse(style="min-height:50vh !important;")
      b-container
        b-row.justify-content-center(style="margin-bottom:4rem;")
          b-col(md="6", style="position:relative;")
            p.my-4.text-center {{ dictionary.tellUs.title }}
            .vertical-divider.d-md-block.d-none(style="position:absolute;right:0;top:0;")
          b-col(md="6")
            .d-flex.align-items-center.justify-content-center(style="height:100%;")
              b-button.px-4(variant="outline-dark", style="padding-bottom:2px;", :to="{name: 'Contact'}") {{ dictionary.contactUs }}
        .text-center
          b-link(href="https://www.linkedin.com/company/legalproject-mx", target="_blank")
            b-img(height="30", src="@/assets/images/linkedin.png")
    .d-flex.align-items-center.justify-content-center.p-4(style="background:white;")
      p.mb-0.text-center.bold ®Legal Project S.C. 2020
</template>

<script>
import { Carousel, Slide } from 'vue-carousel';
import globalMixin from '@/mixins/globalMixin'

import toolbarComponent from '@/components/toolbarComponent'

export default {
  mixins: [globalMixin],
  name: 'Home',
  methods: {
    generateImage (src) {
      return require('@/assets/images/' + src);
    }
  },
  components: {
    Carousel,
    Slide,
    toolbarComponent
  }
}
</script>

<style lang="scss">
  .cont {
    padding-top: 66px;
  }

  .section {
    width: 100%;
    min-height: 70vh;
    padding-top: 5rem;
    padding-bottom: 5rem;

    &.header {
      min-height: calc(100vh - 66px);
    }
  }

  .divider {
    width: 60%;
    height: 2px;
    margin: auto;
    background-color: #979797;
  }

  .vertical-divider {
    width: 1px;
    height: 100%;
    background-color: #979797;
  }

  ul {
    margin: 0;
    padding: 0;
  }
  ul.dashed {
    list-style-type: none;
  }
  ul.dashed > li {
    text-indent: -5px;
  }
  ul.dashed > li:before {
    content: "-";
    text-indent: -5px;
  }

  .VueCarousel-dot {
    border: 1px solid black;
  }

  .gradient {
    background: rgb(250,247,245);
    background: -moz-linear-gradient(0deg, rgba(250,247,245,1) 30%, rgba(255,255,255,1) 100%);
    background: -webkit-linear-gradient(0deg, rgba(250,247,245,1) 30%, rgba(255,255,255,1) 100%);
    background: linear-gradient(0deg, rgba(250,247,245,1) 30%, rgba(255,255,255,1) 100%);
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#faf7f5",endColorstr="#ffffff",GradientType=1);
  }

  .gradient-reverse {
    background: rgb(255,255,255);
    background: -moz-linear-gradient(0deg, rgba(255,255,255,1) 30%, rgba(250,247,245,1) 100%);
    background: -webkit-linear-gradient(0deg, rgba(255,255,255,1) 30%, rgba(250,247,245,1) 100%);
    background: linear-gradient(0deg, rgba(255,255,255,1) 30%, rgba(250,247,245,1) 100%);
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#ffffff",endColorstr="#faf7f5",GradientType=1);
  }

  .toolbar {
    position: fixed;
    top: 0;
    width: 100%;
    // padding: 13px 0;
    background: white;
    z-index: 1;
  }

  .navbar-brand {
    transform: translateX(-50%);
    left: 50%;
    position: absolute;
    top: 8px;
  }

  .rotate {
    transform: rotate(45deg);
  }

  .navbar-toggler {
    outline: none !important;
  }

  img.menu-icon {
    transition: 0.2s;
    cursor: pointer;
  }

  .mi {
    opacity: 0;
  }

  .appear {
    -webkit-animation: appear .3s;
            animation: appear .3s;
    -webkit-animation-fill-mode: forwards;
            animation-fill-mode: forwards;
  }

  @-webkit-keyframes appear {
    from {
      opacity: 0;
      -webkit-transform: translate(0, 10px);
              transform: translate(0, 10px);
    }
    to {
      opacity: 1;
      -webkit-transform: translate(0, 0);
              transform: translate(0, 0);
    }
  }

  @keyframes appear {
    from {
      opacity: 0;
      -webkit-transform: translate(0, 10px);
              transform: translate(0, 10px);
    }
    to {
      opacity: 1;
      -webkit-transform: translate(0, 0);
              transform: translate(0, 0);
    }
  }
</style>
