import lang from '@/lang'

export default {
  data: function () {
    return {
      menuOpen: false,
    }
  },
  methods: {
    changeLanguage () {
      if (this.language == 'en') {
        this.$router.push({ query: { lang: 'es' } })
      } else {
        this.$router.push({ query: { lang: 'en' } })
      }
    }
  },
  computed: {
    language () {
      return this.$route.query.lang || 'en'
    },
    dictionary () {
      return lang[this.language] || lang['en']
    }
  }
}
