<template lang="pug">
  b-navbar.justify-content-center.align-items-center(toggleable="md", type="light", fixed="top", style="background:white;padding-top:13px;padding-bottom:13px;")
    b-container
      b-navbar-nav
        b-nav-text
          b-link.text-reset(@click="changeLanguage") Eng/Esp
      //-
      b-navbar-brand.mx-auto(:to="{name: 'Home'}")
        b-img(src="@/assets/images/full-logo.png", height="40")

      //- b-navbar-nav.d-none.d-md-block
      //-   b-img(src="@/assets/images/menu-icon.png", height="10", style="cursor:pointer;")

      b-navbar-toggle(target="menu", style="border: 0;")
        template(v-slot:default="{ expanded }")
          b-img.menu-icon(src="@/assets/images/menu-icon.png", height="10", :class="{ rotate: expanded }")
      b-collapse(id="menu", is-nav, style="flex-grow:initial;")
        b-navbar-nav.ml-auto.d-flex.d-md-none
          b-nav-item(:to="{name: 'Home'}") {{ dictionary.menu.about }}
          b-nav-item(:to="{name: 'Contact'}") {{ dictionary.menu.contact }}

      b-navbar-nav.ml-auto.d-none.d-md-flex
        b-navbar-nav
          b-nav-item.mi(:class="menuOpen ? 'appear' : 'disappear'", :to="{name: 'Home'}") {{ dictionary.menu.about }}
          b-nav-item.mi(:class="menuOpen ? 'appear' : 'disappear'", :to="{name: 'Contact'}") {{ dictionary.menu.contact }}
          b-nav-item(@click="menuOpen = !menuOpen")
            b-img.menu-icon(src="@/assets/images/menu-icon.png", height="10", style="cursor:pointer;", :class="{ rotate: menuOpen }", data-toggle="collapse" data-target="#second-menu")
</template>

<script>
import globalMixin from '@/mixins/globalMixin'

export default {
  mixins: [globalMixin],
}
</script>

<style lang="css" scoped>
</style>
