<template lang="pug">
  div.cont
    toolbar-component

    .section.header.d-flex.gradient-reverse(style="padding-bottom:3rem;")
      b-container
        b-row.justify-content-center.mb-4
          b-col(md="8")
            h3.mb-0.roman-title.text-center {{ dictionary.contact.title }}
        b-row.justify-content-center.mb-4
          b-col(md="8")
            p.mb-0.text-center {{ dictionary.contact.subtitle }}
        validation-observer(ref="person_observer", v-slot="{ invalid, handleSubmit, errors }")
          b-form.w-100.mb-5(@submit.prevent="handleSubmit(sendInfo)")
            b-row.justify-content-center
              b-col(md="8")
                b-row
                  b-col
                    p {{ dictionary.contact.name }} *
                b-row
                  b-col(md="6")
                    validation-provider(rules="required",  v-slot="{ valid, errors }")
                      b-form-group(:description="dictionary.contact.firstName")
                        b-form-input(:state="errors[0] ? false : (valid ? true : null)", v-model="form.first_name")
                  b-col(md="6")
                    validation-provider(rules="required",  v-slot="{ valid, errors }")
                      b-form-group(:description="dictionary.contact.lastName")
                        b-form-input(:state="errors[0] ? false : (valid ? true : null)", v-model="form.last_name")

                b-row
                  b-col(md="6")
                    validation-provider(rules="required|email",  v-slot="{ valid, errors }")
                      b-form-group(:label="dictionary.contact.email + '*'")
                        b-form-input(:state="errors[0] ? false : (valid ? true : null)", v-model="form.email")
                  b-col(md="6")
                    validation-provider(rules="required",  v-slot="{ valid, errors }")
                      b-form-group(:label="dictionary.contact.company + '*'")
                        b-form-input(:state="errors[0] ? false : (valid ? true : null)", v-model="form.company")
                  b-col
                    validation-provider(rules="required",  v-slot="{ valid, errors }")
                      b-form-group(:label="dictionary.contact.message + '*'")
                        b-form-textarea(rows="3", max-rows="10", no-resize, v-model="form.message", :state="errors[0] ? false : (valid ? true : null)")
                .text-center.mt-3
                  .pt-3.pb-5
                    .g-recaptcha.text-center(data-siteKey="6LePV-gUAAAAAPJUFIW972zium7a2NlCAYne8v-_", data-callback="captchaCallback")
                  p.text-danger(v-if="error") {{ error }}
                  b-alert(show, variant="success", v-if="sent", style="padding-bottom:6px;") Datos enviados
                  b-button(v-else, variant="outline-dark", style="padding-bottom:2px;padding-left:50px;padding-right:50px;", type="submit", :disabled="!captchaValidated || loading") {{ dictionary.contact.send }}
    .d-flex.justify-content-center(style="background:white;padding: 0 0 3rem;")
      b-link(href="https://www.linkedin.com/company/legalproject-mx", target="_blank")
        b-img(height="30", src="@/assets/images/linkedin.png")
    .d-flex.align-items-center.justify-content-center.p-4(style="background:white;")
      p.mb-0.text-center.bold ®Legal Project S.C. 2020
</template>

<script>
import globalMixin from '@/mixins/globalMixin'

import toolbarComponent from '@/components/toolbarComponent'
import axios from 'axios'

import Vue from 'vue';
const EventBus = new Vue();
window.captchaCallback = function (ev) {
  EventBus.$emit('captcha', ev);
}

export default {
  mixins: [globalMixin],
  name: 'Contact',
  data () {
    return {
      loading: false,
      sent: false,
      error: '',
      menuOpen: false,
      form: {
        first_name: '',
        last_name: '',
        email: '',
        company: '',
        message: ''
      },
      captchaValidated: false
    }
  },
  methods: {
    sendInfo (e) {
      this.loading = true;

      axios.post('/sendgrid.php', this.form).then((res) => {
        this.loading = false;

        if (res.data.success) {
          this.sent = true;
        } else if (res.data.message) {
          this.error = res.data.message;
        }
      }).catch(err => {
        this.loading = false;
        this.error = 'Ocurrió un error enviando formulario'
      })
    },
  },
  components: {
    toolbarComponent
  },
  mounted () {
    let script = document.createElement('script');
    script.src = 'https://www.google.com/recaptcha/api.js';
    script.setAttribute('async', true);
    script.setAttribute('defer', true);
    document.getElementsByTagName('body')[0].appendChild(script);

    EventBus.$on('captcha', ev => {
      if (ev != undefined) {
        this.captchaValidated = true;
      }
    });
  }
}
</script>

<style lang="scss">
  .cont {
    padding-top: 66px;
  }

  .section {
    width: 100%;
    min-height: 70vh;
    padding-top: 5rem;
    padding-bottom: 5rem;

    &.header {
      min-height: calc(100vh - 66px);
    }
  }

  .divider {
    width: 60%;
    height: 2px;
    margin: auto;
    background-color: #979797;
  }

  .vertical-divider {
    width: 1px;
    height: 100%;
    background-color: #979797;
  }

  ul {
    margin: 0;
    padding: 0;
  }
  ul.dashed {
    list-style-type: none;
  }
  ul.dashed > li {
    text-indent: -5px;
  }
  ul.dashed > li:before {
    content: "-";
    text-indent: -5px;
  }

  .VueCarousel-dot {
    border: 1px solid black;
  }

  .gradient {
    background: rgb(250,247,245);
    background: -moz-linear-gradient(0deg, rgba(250,247,245,1) 30%, rgba(255,255,255,1) 100%);
    background: -webkit-linear-gradient(0deg, rgba(250,247,245,1) 30%, rgba(255,255,255,1) 100%);
    background: linear-gradient(0deg, rgba(250,247,245,1) 30%, rgba(255,255,255,1) 100%);
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#faf7f5",endColorstr="#ffffff",GradientType=1);
  }

  .gradient-reverse {
    background: rgb(255,255,255);
    background: -moz-linear-gradient(0deg, rgba(255,255,255,1) 30%, rgba(250,247,245,1) 100%);
    background: -webkit-linear-gradient(0deg, rgba(255,255,255,1) 30%, rgba(250,247,245,1) 100%);
    background: linear-gradient(0deg, rgba(255,255,255,1) 30%, rgba(250,247,245,1) 100%);
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#ffffff",endColorstr="#faf7f5",GradientType=1);
  }

  .toolbar {
    position: fixed;
    top: 0;
    width: 100%;
    // padding: 13px 0;
    background: white;
    z-index: 1;
  }

  .navbar-brand {
    transform: translateX(-50%);
    left: 50%;
    position: absolute;
    top: 5px;
  }

  .rotate {
    transform: rotate(45deg);
  }

  .navbar-toggler {
    outline: none !important;
  }

  img.menu-icon {
    transition: 0.2s;
    cursor: pointer;
  }

  .mi {
    opacity: 0;
  }

  .appear {
    -webkit-animation: appear .3s;
            animation: appear .3s;
    -webkit-animation-fill-mode: forwards;
            animation-fill-mode: forwards;
  }

  @-webkit-keyframes appear {
    from {
      opacity: 0;
      -webkit-transform: translate(0, 10px);
              transform: translate(0, 10px);
    }
    to {
      opacity: 1;
      -webkit-transform: translate(0, 0);
              transform: translate(0, 0);
    }
  }

  @keyframes appear {
    from {
      opacity: 0;
      -webkit-transform: translate(0, 10px);
              transform: translate(0, 10px);
    }
    to {
      opacity: 1;
      -webkit-transform: translate(0, 0);
              transform: translate(0, 0);
    }
  }

  .g-recaptcha > div {
    margin: auto;
  }
</style>
